import classNames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import React from 'react';
import { isTrentaNTVArticle } from '../../../common/getSourceProjectId';

const TABOOLA_SCRIPT_SRC_REGEXP = /^https:\/\/cdn.taboola.com\/scripts\/eid.es5.js$/;
const AD_BLOCK_SRC = 'https://tagan.adlightning.com/fluct-ntv/op.js';

/**
 * uiViewのタイプからTaboolaの画面指定の文字列を返す
 * @param {string} uiView 対象画面のuiView文字列
 * @return {string} Taboolaとしての対応するUI種別の文字列
 */
function getTaboolaUiView(uiView) {
  switch (uiView) {
    case 'home':
    case 'companyHome':
      return 'homepage';
    case 'articleDetail':
      return 'article';
    case 'new':
      return 'category';
    case 'ArticlesContentImages':
    case 'ArticleDetailContentImages':
    case 'ArticleDetailContentImagesEachCompany':
      return 'photo';
    default:
      // 上記以外のページは全てカテゴリーとして返す
      return 'category';
  }
}

/**
 * Taboola共通タグ（headタグ）のスクリプトタグ生成関数
 * @param {boolean} isGDPRCountry GDPR対象国からのアクセスか？
 * @param {string} uiView uiViewの文字列
 * @return {React.JSX.Element} スクリプトタグ
 */
export function getRecommendTag(isGDPRCountry, uiView) {
  if (!isGDPRCountry && uiView) {
    const taboolaUiType = getTaboolaUiView(uiView);
    return (
      <React.Fragment>
        {/* Taboola (レコメンド) 利用タグ */}
        <script defer
          dangerouslySetInnerHTML={{
            __html: `
              window._taboola = window._taboola || [];
              _taboola.push({${taboolaUiType}:'auto'});
              !function (e, f, u, i) {
                if (!document.getElementById(i)){
                  e.async = 1;
                  e.src = u;
                  e.id = i;
                  f.parentNode.insertBefore(e, f);
                }
              }
              (
                document.createElement('script'),
                document.getElementsByTagName('script')[0],
                '//cdn.taboola.com/libtrc/ntvnews/loader.js',
                'tb_loader_script'
              );
              if (window.performance && typeof window.performance.mark == 'function') {
                window.performance.mark('tbl_ic');
              };
            `,
          }}
        />
        <script defer src="https://flux-cdn.com/client/00173/trenta_01464.min.js" />
      </React.Fragment>
    )
  }
  return null;
}

class TaboolaWidget extends React.Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    isLazy: PropTypes.bool,
    target: PropTypes.string, // below0 / below1 / below2 / ranking / recommend
    className: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.taboolaParam = this.getTaboolaParam(_get(props, 'target', ''));
    this.isGDPRCountry = _get(context, 'models.config.data.isGDPRCountry', false);
    this.isLazy = _get(props, 'isLazy', false);
    this.getTaboolaUiType = this.getTaboolaUiType.bind(this);
  }

  /**
   * Taboolaの画面タイプを返却する
   * main / mainsub / sub
   */
  getTaboolaUiType(taboolaUiView) {
    const isEachCompany = _get(this.context, 'routeHandler.isEachCompany', false);
    const isEachTrenta = _get(this.context, 'routeHandler.eachTrenta', false);
    const companyCode = _get(this.context, 'routeHandler.params.companyCode', '');

    switch (taboolaUiView) {
      case 'homepage':
      case 'category':
        // 各局UI以外のトップは全てmainを返す
        if (isEachCompany && !isEachTrenta && companyCode) {
          return 'sub';
        }
        return 'main';
      case 'article':
      case 'photo':
        if (isEachCompany) {
          return 'sub';
        } else {
          const articleId = _get(this.context, 'routeHandler.params.id');
          const providerPrefix = _get(this.context, 'models.config.data.providerPrefix', {});
          const type = isTrentaNTVArticle(articleId, providerPrefix) ? 'main' : 'mainsub';
          return type;
        }
      case 'category':
        if (isEachCompany && !isEachTrenta && companyCode) {
          return 'sub';
        }
        return 'main';
      default:
        // 現状デフォルトに入るものはないはず.保険的実装
        if (isEachCompany) {
          return 'sub';
        }
        if (!isEachCompany && !isEachTrenta && !companyCode) {
          return 'main';
        }
        if (!isEachCompany && isEachTrenta && companyCode) {
          return 'mainsub';
        } else {
          const articleId = _get(this.context, 'routeHandler.params.id');
          const providerPrefix = _get(this.context, 'models.config.data.providerPrefix', {});
          const type = isTrentaNTVArticle(articleId, providerPrefix) ? 'main' : 'mainsub';
          return type;
        }
    }
  }

  /**
   * Taboola 利用パラメータ取得
   * @param {String} target 利用するパラメータのキー設定 recommend / ranking / below0 / below1 / below2
   * @returns {Object} TABOOLA_PARAMから抽出した対象パラメータを返却 (存在しない場合は、空{})
   */
  getTaboolaParam(target) {
    if (_isEmpty(target)) return {};
    const TABOOLA_PARAM = _get(this.context, 'models.config.data.taboolaConfig', {});
    const taboolaUiView = getTaboolaUiView(_get(this.context, 'routeHandler.uiView'));
    const taboolaType = this.getTaboolaUiType(taboolaUiView);
    const deviceType = _get(this.context, 'spMode') ? 'sp' : 'pc';
    const param = _get(TABOOLA_PARAM, [taboolaUiView, target, taboolaType, deviceType], {});
    return param;
  }

  /**
   * head内のTaboolaタグの直上にAdブロックスクリプトを差し込む
   */
  insertAdblockTag(head) {
    if (!head) return;

    const headScriptTags = head.getElementsByTagName('script');
    const adBlockScript = document.createElement('script');
    adBlockScript.defer = true;
    adBlockScript.src = AD_BLOCK_SRC;

    _forEach(headScriptTags, (script) => {
      const scriptSrc = script.getAttribute('src');
      if ((scriptSrc !== adBlockScript.src) && scriptSrc.match(TABOOLA_SCRIPT_SRC_REGEXP)) {
        document.head.insertBefore(adBlockScript, script);
        this.observer.disconnect();
      }
    })
  }

  getAdInvalidFlag = () => {
    return this.isGDPRCountry || _get(this.props, 'invisibleAd', false)
  }

  /**
   * おすすめでは描画後に処理が走るため、componentDidMount内で実行する
   */
  componentDidMount() {
    this._isMounted = true;
    if (this.isLazy) {
      window._taboola = window._taboola || [];
      _taboola.push({
        mode: this.taboolaParam.mode,
        container: this.taboolaParam.container,
        placement: this.taboolaParam.placement,
        target_type: this.taboolaParam.target_type,
      });
    if (!this.getAdInvalidFlag()) {
      this.observer = new MutationObserver((mutations) => {
        _.forEach(mutations, (mutation) => this.insertAdblockTag(mutation.target));
      })
      this.observer.observe(document.head, {
        childList: true,
        attributes: true
      });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.observer?.disconnect();
  }

  render() {
    if (this.getAdInvalidFlag()) {
      // Taboola利用画面・GDPR 対象国の場合、非表示
      // 広告が無効の場合も非表示
      console.warn('Your environment is not allowed to display advertisements because you are in a country subject to GDPR or because you are not permitted to display advertisements.');
      return null;
    }

    const taboola = this.taboolaParam;
    const addClassNames = _get(this.props, 'className', '');
    return (
      <React.Fragment>
        <div id={taboola.container} className={classNames('taboola-widget', addClassNames)}></div>
        {!this.isLazy && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window._taboola = window._taboola || []; _taboola.push({
                mode: '${taboola.mode}',
                container: '${taboola.container}',
                placement: '${taboola.placement}',
                target_type: '${taboola.target_type}',
              });
            `,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
export default TaboolaWidget;
